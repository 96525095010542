div.orgChart {
  font-family: gadugi;
  border: 0px solid #cccccc;
  background-color: #FFF;
  /* background-image: url("../Images/StructuralOrg/woodwall.png"); */
  padding: 20px 20px 60px 20px;
}
div.orgChart div.node {
  width: 200px;
  height: 315px;
  background-color: white;
  padding: 0.5em;
}

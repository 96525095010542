@font-face{font-family:'gadugi'; src:url(Fonts/gadugi.ttf);}

body #fonts {
  margin: 0;
  font-family: gadugi, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FF8C00;
}
